import { navigate } from 'gatsby-link';
import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookSquare } from 'react-icons/fa';
import { connect } from 'react-redux';
import logo from '../images/logo.webp';
import { checkPageLoading } from '../state/auth/auth.actions';

const isBrowser = typeof window !== 'undefined';

const Footer = ({ checkPageLoading }) => {
  const handleClick = (ev, link) => {
    if (isBrowser && !window.location.pathname.includes(link)) {
      ev.preventDefault();
      checkPageLoading(true);
      navigate(`/${link}`);
    }
  };
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 gray-bg" id="footer"
    data-aos="fade-up"
    data-aos-duration="900">
      <div className="grid pb-6 border-bottom-1 border-300">
        <div className="col-12 md:col-3"
        data-aos="fade-left"
        data-aos-duration="1100">
          <div className="text-900 font-bold line-height-3 mb-3">Contacts</div>
          <a className="text-700 block cursor-pointer line-height-3 mb-2"
          onClick={(ev) => {
            ev.preventDefault();
            window.open('mailto:admin@ecoliteminerals.com');
          }}>
            admin@ecoliteminerals.com
          </a>
          <a className="text-700 block line-height-3 mb-2">
            +254 759 012 220
          </a>
          <a className="text-700 block line-height-3 mb-3">
            +254 776 172 857
          </a>
          <div className="text-900 font-bold line-height-3 mb-3">Address</div>
          <a className="text-700 block line-height-3">
            Flower Business Park
          </a>
          <a className="text-700 block line-height-3">
            Off Nairobi-Nakuru Highway
          </a>
          <a className="text-700 block line-height-3">
            P.O. BOX 2211-20117
          </a>
          <a className="text-700 block line-height-3">
            Naivasha, Kenya
          </a>
        </div>
        <div className="col-12 md:col-3"
        data-aos="fade-left"
        data-aos-duration="1100">
          <div className="text-900 font-bold line-height-3 mb-3">Products</div>
          <a className="text-700 block cursor-pointer line-height-3 mb-2"
          onClick={(ev) => handleClick(ev, 'vermiculite')} >
            Vermiculite
          </a>
          <a className="text-700 block cursor-pointer line-height-3 mb-2"
          onClick={(ev) => handleClick(ev, 'peat-moss')}>
            Peat Moss
          </a>
          <a className="text-700 block cursor-pointer line-height-3 mb-2"
          onClick={(ev) => handleClick(ev, 'cocopeat')}>
            Cocopeat
          </a>
          <a className="text-700 block cursor-pointer line-height-3 mb-2"
          onClick={(ev) => handleClick(ev, 'perlite')}>
            Perlite
          </a>
          <a className="text-700 block cursor-pointer line-height-3 mb-2"
          onClick={(ev) => handleClick(ev, 'firelighter')}>
            Firelighter
          </a>
        </div>
        <div className="col"
        data-aos="fade-right"
        data-aos-duration="1100">
          <iframe
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10333.734594144906!2d36.43553143999656!3d-0.6616263809297179!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa007dee59cb7de83!2sEcolite%20Minerals%20Kenya!5e0!3m2!1sen!2ske!4v1586964429722!5m2!1sen!2ske"
            style={{ width: '100%', height: '100%' }}
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
      <div className="flex flex-wrap align-items-center justify-content-between mt-6"
      data-aos="fade-up"
      data-aos-duration="1100">
        <div className="flex align-items-center justify-content-start md:mb-0 mb-3">
          <img
            src={logo}
            alt="logo"
            height={50}
            className="mr-0 lg:mr-6"
            style={{ width: '190px', height: '90px' }}
          />
        </div>
        <div className="flex align-items-center justify-content-between">
          <a className="cursor-pointer text-500 md:ml-3 ml-2"
          target="_blank" rel="noreferrer" href="https://www.facebook.com/ecolitemineralskenya/">
            <i>
              <FaFacebookSquare className="text-2xl" />
            </i>
          </a>
          <a className="cursor-pointer text-500 md:ml-3 ml-2"
          target="_blank" rel="noreferrer" href="https://www.instagram.com/ecolitemineralske_/">
            <i>
              <BsInstagram className="text-2xl" />
            </i>
          </a>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

export default connect(null, mapDispatchToProps)(Footer);

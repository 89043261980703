import React from 'react';

const AdditivesLi = ({ icon, text }) => {
  return (
    <li className="additives-li mt-5">
      <span className="icon bg-gray-200 text-xl mr-3 text-green-600">{icon}</span>
      <span className="text text-xl text-gray-900">{text}</span>
    </li>
  );
};

export default AdditivesLi;

import React from 'react';

const ApplicationLi = ({ icon, text }) => {
  return (
    <li className="application-li bg-gray-200">
      <span className="icon text-green-600">{icon}</span>
      <span className="text-gray-900">{text}</span>
    </li>
  );
};

export default ApplicationLi;

import * as React from 'react';

import { connect } from 'react-redux';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import PeatMoss from '../components/peat-moss/PeatMoss';
import Seo from '../components/seo';

const PeatMossPage = () => {
  return (
    <Layout>
      <Seo title="Peat Moss" />
      <PeatMoss />
      <Footer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(PeatMossPage);

import React from 'react';
import { ImLeaf } from 'react-icons/im';
import { GiBirchTrees, GiKiwiFruit, GiFruitTree, GiWaterDrop, GiMineralPearls, GiFertilizerBag } from 'react-icons/gi';
import { BsTreeFill } from 'react-icons/bs';
import introImg from '../../images/intro-img.jpeg';
import peatMoss from '../../images/peatmoss-grades/peat-moss-1.png';
import AdditivesLi from './AdditivesLi';
import PeatMossGallery from './PeatMossGallery';
import ApplicationLi from './ApplicationLi';

const PeatMoss = () => {
  return (
    <div
      className="h-100vh vermiculite-intro-wrapper"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        style={{ height: '200px', background: `url(${introImg}) no-repeat` }}
        className="vermiculite-intro"
      ></div>
      <div className="px-4 py-5 md:px-6 lg:px-8">
        <div
          className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
          style={{ marginTop: '-2rem', top: '-70px', marginBottom: '-70px' }}
        >
          <div>
            <div
              style={{ width: '140px', height: '140px', borderRadius: '10px' }}
              className="mb-3 flex align-items-center justify-content-center"
            >
              <img
                src={peatMoss}
                alt="peat-moss"
                className="border-round"
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <div className="mb-3">
              <div className="border-bottom-1 surface-border">
                <span className="block text-3xl font-medium text-900 mb-4">
                  Peat Moss
                </span>
              </div>
            </div>

            <p className="mt-0 mb-3 text-700 text-xl">
              What is <span className="green-c">Peat Moss</span>?
            </p>
            <div className="text-600 font-medium">
              <p className="mb-3">
                Peat moss is an important component of most potting soils and
                seed starting mediums. It holds several times its weight in
                moisture, and releases the moisture to the plants roots as
                needed. It also holds onto nutrients so that they aren’t rinsed
                out of the soil. The type of vegetation decaying to form part of
                the peat can vary, depending on the region and the nutrient
                content of the peatland. Peat can be used in its pure form or it
                could be blended with other substrates such as vermiculite and
                perlite for different applications.
              </p>
              <p>
                Ecolite Minerals Limited has partnered with one of the{' '}
                {"world's"} leading producers of high quality peat-bog
                substrates for professional growers to make it available in East
                African market
              </p>
              <div className="grid mt-4">
                <div className="col-12 md:col-6">
                  <div className="text-900 font-medium mb-2 text-lg">Composition</div>
                  <div className="text-600 font-medium mb-3 line-height-3">
                    White sphagnum peat
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="text-900 font-medium mb-2 text-lg">
                    Physical properties
                  </div>
                  <div className="text-600 font-medium mb-3 line-height-3">
                    {' '}
                    Fine grade
                  </div>
                </div>
              </div>
              <div className="d-flex align-content-around flex-wrap">
                <div style={{ flex: 1, minWidth: '300px' }} className="mr-3">
                  <h1 className="text-900">Aplications</h1>
                  <ul className="application-ul">
                    <ApplicationLi icon={<ImLeaf />} text="Young plants & seedlings" />
                    <ApplicationLi icon={<BsTreeFill />} text="Forestry & cuttings" />
                    <ApplicationLi icon={<GiBirchTrees />} text="Ornamentals & potting plants" />
                    <ApplicationLi icon={<GiKiwiFruit />} text="Soft fruits" />
                    <ApplicationLi icon={<GiFruitTree />} text="Fruit trees" />
                  </ul>
                </div>
                <div style={{ flex: 1, minWidth: '300px' }}>
                  <h1 className="text-900">Additives</h1>
                  <ul className="additives-ul">
                    <AdditivesLi icon={<GiFertilizerBag />} text="Starter fertilizer" />
                    <AdditivesLi icon={<GiWaterDrop />} text="Wetting agent" />
                    <AdditivesLi icon={<GiMineralPearls />} text="Limestone" />
                  </ul>
                </div>
              </div>
              <PeatMossGallery />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeatMoss;

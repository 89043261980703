import React from 'react';

const SingleGallerySlide = ({ image, style, xtraClasses }) => {
  return (
    <div className="col-12 lg:col-2 lg:m-5">
      <div
        className={`shadow-1 border-round surface-card h-full ${xtraClasses || ''}`}
        style={{ maxHeight: '100%', maxWidth: '100%', minWidth: '200px', ...style }}
      >
        <div className="h-100 w-100">
          <img
            src={image}
            alt="peat-moss"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              minHeight: '100%',
              minWidth: '100%'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleGallerySlide;

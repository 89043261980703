import React from 'react';
import pm3 from '../../images/peat-moss1.jpeg';
import pm2 from '../../images/peatmoss2.jpeg';
import pm4 from '../../images/peatmoss3.jpeg';
import pm1 from '../../images/peat-moss-packaged.jpeg';
import SingleGallerySlide from './SingleGallerySlide';

const PeatMossGallery = () => {
  return (
    <div className="mt-3">
      <div className="grid">
        <SingleGallerySlide image={pm1} />
        <SingleGallerySlide image={pm2} />
        <SingleGallerySlide image={pm3} />
        <SingleGallerySlide image={pm4} />
      </div>
    </div>
  );
};

export default PeatMossGallery;
